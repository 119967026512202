import BaselineOverrides from './overrides/baseline'
import { Components } from '@mui/material/styles/components'
import SvgIconOverrides from './overrides/svgicons'
import { Theme } from '@mui/material/styles/createTheme'

export default function OverridesFunction(theme: Theme): Components {
  return {
    MuiCssBaseline: BaselineOverrides(theme),
    MuiSvgIcon: SvgIconOverrides(theme),
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: '32px',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'always',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            top: '-7px',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          minWidth: '25%',
        },
      },
    },
  }
}
