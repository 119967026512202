exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-barrieremelden-kontaktanfragen-tsx": () => import("./../../../src/pages/barrieremelden-kontaktanfragen.tsx" /* webpackChunkName: "component---src-pages-barrieremelden-kontaktanfragen-tsx" */),
  "component---src-pages-bestellungendaten-tsx": () => import("./../../../src/pages/bestellungendaten.tsx" /* webpackChunkName: "component---src-pages-bestellungendaten-tsx" */),
  "component---src-pages-contactmailsettings-tsx": () => import("./../../../src/pages/contactmailsettings.tsx" /* webpackChunkName: "component---src-pages-contactmailsettings-tsx" */),
  "component---src-pages-feedbacknotificationrecipients-tsx": () => import("./../../../src/pages/feedbacknotificationrecipients.tsx" /* webpackChunkName: "component---src-pages-feedbacknotificationrecipients-tsx" */),
  "component---src-pages-feedbacks-tsx": () => import("./../../../src/pages/feedbacks.tsx" /* webpackChunkName: "component---src-pages-feedbacks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontaktanfragen-tsx": () => import("./../../../src/pages/kontaktanfragen.tsx" /* webpackChunkName: "component---src-pages-kontaktanfragen-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-ordersmailsettings-tsx": () => import("./../../../src/pages/ordersmailsettings.tsx" /* webpackChunkName: "component---src-pages-ordersmailsettings-tsx" */),
  "component---src-pages-shoppingcardmailsettings-tsx": () => import("./../../../src/pages/shoppingcardmailsettings.tsx" /* webpackChunkName: "component---src-pages-shoppingcardmailsettings-tsx" */),
  "component---src-pages-teilnehmerdaten-tsx": () => import("./../../../src/pages/teilnehmerdaten.tsx" /* webpackChunkName: "component---src-pages-teilnehmerdaten-tsx" */)
}

