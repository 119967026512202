import React, { useEffect, useState, createContext } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { navigate } from 'gatsby'

import Loading from '@components/loading'
import { verifyUserToken, loggingIn } from '@services/authservice'
import { setAuthHeader } from '@services/basicapi'

import useSnackbar from '@hooks/useSnackbar'

export interface IAuthContext {
  LoggingIn: (name: string, password: string) => Promise<void>
  LoggingOut: () => void
  loggedIn: boolean
}

const AuthContext = createContext<IAuthContext>({
  LoggingIn: (name: string, password: string) => new Promise(() => {}),
  LoggingOut: () => {},
  loggedIn: false,
})

export type AuthProviderProps = AT.IReactPropsDefaults & {
  path: string
}

export function AuthProvider({ children, path }: AuthProviderProps) {
  const { toggleSnackbar } = useSnackbar()
  const [tokenVeryfied, setTokenVeryfied] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    verifyToken()
  }, [])

  function verifyToken() {
    verifyUserToken()
      .then((token) => {
        if (!!!token) {
          navigate('/login/')
          if (!path.includes('/login/'))
            toggleSnackbar('Bitte loggen Sie sich ein.', 'warning')
        } else {
          setAuthHeader(token)
          setTokenVeryfied(true)
          setLoggedIn(true)
          if (path.includes('/login/')) navigate('/')
        }
      })
      .catch((e) => {
        console.error(e)
        navigate('/login/')
        toggleSnackbar(
          `Bitte wenden Sie sich an einen Administrator - ${e}`,
          'error'
        )
      })
  }

  async function LoggingIn(name: string, password: string) {
    return loggingIn(`Basic ${btoa(`${name}:${password}`)}`)
      .then(() => {
        verifyToken()
        navigate('/')
      })
      .catch((e) => {
        Cookies.remove('basicAuthToken')
        setTokenVeryfied(false)
        setLoggedIn(false)
        toggleSnackbar(
          `Bitte wenden Sie sich an einen Administrator - ${e}`,
          'error'
        )
      })
  }

  function LoggingOut() {
    Cookies.remove('basicAuthToken')
    setTokenVeryfied(false)
    setLoggedIn(false)
    navigate('/login/')
  }

  function switchLoading() {
    return typeof window === 'undefined' ||
      (!tokenVeryfied && !path.includes('/login/')) ? (
      <Loading />
    ) : (
      children
    )
  }
  return (
    <AuthContext.Provider value={{ LoggingIn, LoggingOut, loggedIn }}>
      {switchLoading()}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any,
  path: PropTypes.string.isRequired,
}

export default AuthContext
