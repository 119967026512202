import { Theme } from '@mui/material'
import { Components } from '@mui/material/styles/components'

export default function BaselineOverrides(
  theme: Theme
): Components['MuiCssBaseline'] {
  return {
    styleOverrides: {
      // '@font-face': FontFamily,
      body: {
        a: {
          color: 'inherit',
          textDecoration: 'none',
          transitionProperty: 'all',
          transitionDuration: theme.transitions.duration.standard,
          transitionTimingFunction: theme.transitions.easing.easeInOut,
          outline: 'none',
        },
      },
    },
  }
}
