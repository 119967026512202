import React, { useState } from 'react'

import { Link } from 'gatsby'

import { FormattedMessage } from 'react-intl'
import { AppBar, Toolbar, Typography, Drawer, IconButton } from '@mui/material'
import { MenuRounded } from '@mui/icons-material'
import LogoutButton from '@objects/logoutbutton'
import useAuth from '@hooks/useAuth'

import Navigation from './navigation'
import { styled } from '@mui/system'

const Headline = styled(Link)({})

export default function Header() {
  const { loggedIn } = useAuth()
  const [openNav, setOpenNav] = useState(false)

  return (
    <React.Fragment>
      <Drawer anchor="left" open={openNav} onClose={() => setOpenNav(false)}>
        <Navigation setOpenNav={setOpenNav} />
      </Drawer>
      <AppBar position="static" enableColorOnDark>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mr: 0,
            minWidth: '100%',
          }}
        >
          {loggedIn && (
            <IconButton
              sx={{
                mr: 4,
                color: 'primary.contrastText',
              }}
              aria-label="menu"
              onClick={() => setOpenNav(true)}
              size="large"
            >
              <MenuRounded fontSize="large" />
            </IconButton>
          )}
          <Headline
            sx={{
              color: 'primary.contrastText',
              flexGrow: 1,
            }}
            to="/"
          >
            <Typography variant="h6">
              <FormattedMessage id="app.title" />
            </Typography>
          </Headline>
          {loggedIn && <LogoutButton />}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}
