import { createTheme } from '@mui/material/styles'
import { Theme } from '@mui/material'

import Breakpoints from './breakpoints'
import Palette from './palette'
import Mixins from './mixins'
import OverridesFunction from './overrides'
import Typography from './typography'

export default function createMuiTheme(): Theme {
  const baseTheme = createTheme({
    breakpoints: Breakpoints,
    palette: Palette,
    spacing: 4,
  })

  const typography = Typography(baseTheme)
  const components = OverridesFunction(baseTheme)
  const mixins = Mixins(baseTheme)

  const theme = createTheme({
    ...baseTheme,
    typography,
    components,
    mixins,
  })

  theme.themeName = 'Super Warehouse'

  return theme
}
