import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { ExitToAppRounded } from '@mui/icons-material'

import useAuth from '@hooks/useAuth'

export default function LogoutButton() {
  const { LoggingOut } = useAuth()

  return (
    <Tooltip arrow title={'Logout'}>
      <IconButton onClick={() => LoggingOut()} size="large">
        <ExitToAppRounded
          sx={{
            color: 'primary.contrastText',
            fontSize: '28px',
          }}
        />
      </IconButton>
    </Tooltip>
  )
}
