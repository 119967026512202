import React, { Dispatch, SetStateAction } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useSnackbar from '@hooks/useSnackbar'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
} from '@mui/material'

import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { postNotificationRecipients as postShoppingCardNotificationRecipients } from '@services/shoppingcardservice'
import { postNotificationRecipients as postContactNotificationRecipients } from '@services/contactservice'
import {
  postNotificationRecipients as postFeedbackNotificationRecipients,
} from '@services/feedbackuserservice'
import {
  postNotificationRecipients as postMinistryNotificationRecipients,
} from '@services/orderscardservice'
import { CustomDialogProps } from '..'

type AddRecipientFormValuesType = {
  name?: string
  email: string
}

export type AddRecipientProps = {
  variant: CustomDialogProps['variant']
  setOpen: Dispatch<SetStateAction<boolean>>
  onOk: Function | null
}

export default function AddRecipientDialog({
  setOpen,
  variant,
  onOk,
}: AddRecipientProps) {
  const intl = useIntl()
  const { toggleSnackbar } = useSnackbar()

  const initialValues: AddRecipientFormValuesType = {
    name: '',
    email: '',
  }

  const validationSchema = Yup.object({
    name: Yup.string(),
    email: Yup.string()
      .email()
      .required(intl.formatMessage({ id: 'form.validation.required' })),
  })

  function onSubmitClick(
    values: AddRecipientFormValuesType,
    actions: FormikHelpers<AddRecipientFormValuesType>
  ): void {
    if (variant === 'shoppingcard') {
      postShoppingCardNotificationRecipients(values)
        .then(() => {
          toggleSnackbar(
            intl.formatMessage({ id: 'snackbar.success' }),
            'success'
          )
          actions.setSubmitting(false)
          if (onOk) onOk()
          setOpen(false)
        })
        .catch((e) => {
          console.log(e)
          toggleSnackbar(intl.formatMessage({ id: 'snackbar.error' }), 'error')
        })
    } else if (variant === 'feedbackNotification') {
      postFeedbackNotificationRecipients(values)
        .then(() => {
          toggleSnackbar(
            intl.formatMessage({ id: 'snackbar.success' }),
            'success'
          )
          actions.setSubmitting(false)
          if (onOk) onOk()
          setOpen(false)
        })
        .catch((e) => {
          console.log(e)
          toggleSnackbar(intl.formatMessage({ id: 'snackbar.error' }), 'error')
        })
    } 
    else if (variant === 'ministry') {
      postMinistryNotificationRecipients(values)
        .then(() => {
          toggleSnackbar(
            intl.formatMessage({ id: 'snackbar.success' }),
            'success'
          )
          actions.setSubmitting(false)
          if (onOk) onOk()
          setOpen(false)
        })
        .catch((e) => {
          console.log(e)
          toggleSnackbar(intl.formatMessage({ id: 'snackbar.error' }), 'error')
        })
    }
     else {
      postContactNotificationRecipients(values)
        .then(() => {
          toggleSnackbar(
            intl.formatMessage({ id: 'snackbar.success' }),
            'success'
          )
          actions.setSubmitting(false)
          if (onOk) onOk()
          setOpen(false)
        })
        .catch((e) => {
          console.log(e)
          toggleSnackbar(intl.formatMessage({ id: 'snackbar.error' }), 'error')
        })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitClick}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }) => (
        <Form>
          <DialogTitle>
            <FormattedMessage id="dialog.addrecipient.title" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {variant === 'feedback' ? (
                <FormattedMessage id="feedback.addrecipient.copy" />
              ) : (
                <FormattedMessage id="dialog.addrecipient.copy" />
              )}
            </DialogContentText>
            <Grid container spacing={4} direction="column">
              {variant !== 'feedback' && (
                <Grid item>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={!!touched.name && !!errors.name && errors.name}
                  />
                </Grid>
              )}
              <Grid item>
                <TextField
                  id="email"
                  name="email"
                  label="E-Mail Adresse"
                  fullWidth
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={!!touched.email && !!errors.email && errors.email}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} disabled={isSubmitting}>
              <FormattedMessage id="button.abort" />
            </Button>
            <Button type="submit" color="primary" disabled={isSubmitting}>
              <FormattedMessage id="button.ok" />
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}
