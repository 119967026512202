import React from 'react'
import { Box } from '@mui/material'

export default function Loading() {
  return (
    <Box zIndex="modal" position="fixed" width={1} height={1}>
      Laden...
    </Box>
  )
}
