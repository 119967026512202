import Cookies from 'js-cookie'
import apiClient, { setAuthHeader } from './basicapi'

function verifyUserToken(): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    return resolve(Cookies.get('basicAuthToken'))
  })
}

function loggingIn(token: string) {
  setAuthHeader(token)
  Cookies.set('basicAuthToken', token, { expires: 7 })
  return apiClient.get('shoppingcard/admin/Orders')
}

export { verifyUserToken, loggingIn }
