import React, { Dispatch, SetStateAction } from 'react'
import { FormattedMessage } from 'react-intl'

import { WarningRounded } from '@mui/icons-material'

import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from '@mui/material'

export type AreYouSureProps = {
  setOpen: Dispatch<SetStateAction<boolean>>
  onOk?: Function | null
}

export default function AreYouSureDialog({ setOpen, onOk }: AreYouSureProps) {
  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item>
            <WarningRounded fontSize="large" />
          </Grid>
          <Grid item>
            <FormattedMessage id="dialog.areyousure.title" />
          </Grid>
          <Grid item>
            <WarningRounded fontSize="large" />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="dialog.areyousure.copy" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>
          <FormattedMessage id="button.abort" />
        </Button>
        <Button
          type="submit"
          onClick={() => {
            if (onOk) onOk()
            setOpen(false)
          }}
          color="primary"
        >
          <FormattedMessage id="button.iamsure" />
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}
