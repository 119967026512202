import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'

import {
  IconButton,
  Toolbar,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from '@mui/material'

import {
  ForumRounded,
  AccessibilityNewRounded,
  ExitToAppRounded,
  ChevronLeftRounded,
  GetAppRounded,
  MailRounded,
  ListRounded,
  GroupRounded
} from '@mui/icons-material'

import useAuth from '@hooks/useAuth'

export type NavigationProps = {
  setOpenNav: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Navigation({ setOpenNav }: NavigationProps) {
  const { LoggingOut } = useAuth()

  return (
    <React.Fragment>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton onClick={() => setOpenNav(false)} size="large">
          <ChevronLeftRounded />
        </IconButton>
      </Toolbar>
      <Divider />
      <Grid
        sx={{
          height: '100%',
        }}
        container
        direction="column"
        justifyContent="space-between"
        role="presentation"
        onClick={() => setOpenNav(false)}
      >
        <Grid item container spacing={4} direction="column">
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                mt: 4,
                mb: 0,
                mx: 4,
              }}
            >
              <FormattedMessage id="navgiation.shoppingcard.headline" />
            </Typography>
            <List>
              <ListItem button component={Link} to="/">
                <ListItemIcon>
                  <GetAppRounded />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="navigation.shoppingcard.orders" />
                </ListItemText>
              </ListItem>
              <ListItem button component={Link} to="/shoppingcardmailsettings">
                <ListItemIcon>
                  <MailRounded />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="navigation.shoppingcard.emailsettings" />
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <Divider />
            <Typography
              variant="h3"
              sx={{
                mt: 4,
                mb: 0,
                mx: 4,
              }}
            >
              <FormattedMessage id="navgiation.contact.headline" />
            </Typography>
            <List>
              <ListItem button component={Link} to="/kontaktanfragen/">
                <ListItemIcon>
                  <ForumRounded />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="navigation.contact.contactform" />
                </ListItemText>
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/barrieremelden-kontaktanfragen/"
              >
                <ListItemIcon>
                  <AccessibilityNewRounded />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="navigation.contact.accessibilityform" />
                </ListItemText>
              </ListItem>
              <ListItem button component={Link} to="/contactmailsettings">
                <ListItemIcon>
                  <MailRounded />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="navigation.contact.emailsettings" />
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <Divider />
            <Typography
              variant="h3"
              sx={{
                mt: 4,
                mb: 0,
                mx: 4,
              }}
            >
              <FormattedMessage id="navgiation.crossword.headline" />
            </Typography>
            <List>
              <ListItem button component={Link} to="/teilnehmerdaten/">
                <ListItemIcon>
                  <ListRounded />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="navigation.crossword.participants.data" />
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <Divider />
            <Typography
              variant="h3"
              sx={{
                mt: 4,
                mb: 0,
                mx: 4,
              }}
            >
              <FormattedMessage id="navgiation.feedback.headline" />
            </Typography>
            <List>
              <ListItem button component={Link} to="/feedbacks/">
                <ListItemIcon>
                  <ListRounded />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="navigation.feedbacks.headline" />
                </ListItemText>
              </ListItem>
              <ListItem button component={Link} to="/feedbacknotificationrecipients/">
                <ListItemIcon>
                  <MailRounded />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="feedback.notification.recipients" />
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <Divider />
            <Typography
              variant="h3"
              sx={{
                mt: 4,
                mb: 0,
                mx: 4,
              }}
            >
              <FormattedMessage id="navgiation.ministry.headline" />
            </Typography>
            <List>
              <ListItem button component={Link} to="/bestellungendaten/">
                <ListItemIcon>
                  <ListRounded />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="navigation.ministry.data" />
                </ListItemText>
              </ListItem>
              <ListItem button component={Link} to="/ordersmailsettings">
                <ListItemIcon>
                  <MailRounded />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="navigation.ministry.emailsettings" />
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
          <List>
            <ListItem
              button
              onClick={() => {
                LoggingOut()
              }}
            >
              <ListItemIcon>
                <ExitToAppRounded />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="navigation.logout" />
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
