import { Theme } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import FontSize from './definitions/fontSize'

export default function TypographyOverrides(theme: Theme): TypographyOptions {
  return {
    body1: {
      lineHeight: 1.55,
      [theme.breakpoints.up('lg')]: {
        lineHeight: 1.75,
      },
    },
    body2: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['base'],
      lineHeight: 1.625,
    },
    h1: {
      fontSize: FontSize['4xl'],
      lineHeight: 1.2,
      fontWeight: 400,
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['6xl'],
      },
    },
    h2: {
      fontSize: FontSize['3xl'],
      lineHeight: 1.2,
      fontWeight: 400,
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['5xl'],
      },
    },
    h3: {
      fontSize: FontSize['xl'],
      lineHeight: 1.3,
      fontWeight: 500,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['3xl'],
      },
    },
    h4: {
      fontSize: FontSize['2lg'],
      lineHeight: 1.2,
      fontWeight: 400,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['xl'],
      },
    },
    h5: {
      fontSize: FontSize['lg'],
      lineHeight: 1.2,
      fontWeight: 500,
      marginBottom: theme.spacing(2),
    },
    caption: {
      fontSize: FontSize['sm'],
      lineHeight: 1.14,
      color: theme.palette.text.secondary,
      fontStyle: 'normal',
    },
  }
}
