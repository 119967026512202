import React from 'react'

import { Container } from '@mui/material'

import Header from '@components/header'

export type LayoutProps = AT.IReactPropsDefaults & {
  pageContext: AT.IPageContext
}

export default function Layout({ children }: LayoutProps) {
  return (
    <React.Fragment>
      <Header />
      <Container>{children as React.ReactChildren}</Container>
    </React.Fragment>
  )
}
