import { Theme } from '@mui/material/styles'
import FontSize from '../definitions/fontSize'
import { Components } from '@mui/material/styles/components'

export default function SvgIconOverrides(
  theme: Theme
): Components['MuiSvgIcon'] {
  return {
    styleOverrides: {
      root: {
        fontSize: FontSize['2lg'],
        verticalAlign: 'top',
      },
      fontSizeSmall: {
        fontSize: FontSize['baseXS'],
        [theme.breakpoints.up('lg')]: {
          fontSize: FontSize['base'],
        },
      },
      fontSizeLarge: {
        fontSize: FontSize['2xl'],
      },
    },
  }
}
