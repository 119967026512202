import apiClient from './basicapi'

export interface IBundle {
  uniqueId: string
  name: string
  filename: string
  category: string
}

export interface IOrder {
  loginToken: string
  contactData: {
    firstname: string
    lastname: string
    ministry: string
    federalState: string
    streetAndNumber: string
    postcode: string
    city: string
    email: string
    phone: string
    website: string
    pressPackage: boolean
    socialMediaPackage: boolean
  }
  multimediaPillar: {
    contactPerson: string
    phone: string
    email: string
    annotations: string
  }
  feedback: {
    actionIdeas: string
    topicRequests: string
    annotations: string
  }
  bigEvents: {
    name: string
    contact: {
      contactPerson: string
      phone: string
      email: string
    }
    date: string
    reason: string
    numberOfExpectedVisitors: string
  }[]

  smallEvents: {
    name: string
    contact: {
      contactPerson: string
      phone: string
      email: string
    }
    details: [
      {
        date: string
        reason: string
        deliveryAddress: string
      }
    ]
  }[]

  poster: {
    name: string
    numberOfPieces: number
    size: string
  }[]

  bridgeBanners: {
    name: string
    numberOfPieces: number
    windPermeable: boolean
  }[]

  promotionBanners: {
    name: string
    numberOfPieces: number
    windPermeable: boolean
  }[]

  promoStuff: {
    name: string
    numberOfPieces: number
  }[]

  termsOfPrivacy: boolean
}

export interface INotificationRecipient {
  id: number
  name: string
  email: string
}

export interface IPostNotificationRecipient {
  name?: string
  email: string
}

const apiRoot = 'orderformforministries/admin/'

export function getNotificationRecipients(): Promise<
  Array<INotificationRecipient>
> {
  return apiClient
    .get(`${apiRoot}NotificationRecipients`)
    .then((res) => res.data)
}

export function postNotificationRecipients(
  recipient: IPostNotificationRecipient
) {
  return apiClient.post(`${apiRoot}NotificationRecipients`, recipient)
}

export function putNotificationRecipients(
  id: number,
  recipient: IPostNotificationRecipient
) {
  return apiClient.put(`${apiRoot}NotificationRecipients/${id}`, recipient)
}

export function deleteNotificationRecipients(id: number) {
  return apiClient.delete(`${apiRoot}NotificationRecipients/${id}`)
}
