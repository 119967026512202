import apiClient from './basicapi'

const apiRoot = 'feedback/admin/'

export type IDataProps = {
  address: string
  ambassadors: string
  bigEvent: boolean
  company: string
  date: string
  duration: string
  email: string
  firstname: string
  lastname: string
  visitors: string
  materials: string
  materialsAmount: string
  ministry: string
  missingMaterials: string
  organisation: string
  wishes: string
  drugs: string
  alcohol: string
  satisfied: string
  technicalDefects: string
  occasion: string
  phone: string
  venue: string
  place: string
  regions: string
  smallEvent: boolean
  termsOfPrivacy: boolean
  topicOfAction: string
}

export type IFeedbacksPropsRaw = {
  id: string
  timestamp: string
  data: string
  files: string[]
}
export type IFeedbacksProps = {
  id: string
  timestamp: string
  data: IDataProps
  files: string[]
}

export interface IFeedbackNotificationRecipient {
  id: number
  name: string
  email: string
}

export interface IPostFeedbackNotificationRecipient {
  name?: string
  email: string
}

function isValidJSON(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function getFeedbacks() {
  return apiClient
    .get(`${apiRoot}Feedbacks`)
    .then((res: { data: IFeedbacksPropsRaw[] }) => {
      const newData = res.data.map((singleData: IFeedbacksPropsRaw) => ({
        id: singleData.id,
        timestamp: singleData.timestamp,
        data: isValidJSON(singleData.data) ? JSON.parse(singleData.data) : undefined,
        files: singleData.files,
      }))
      return newData
    })
    .catch((error) => {
      console.error('Error fetching feedbacks:', error)
      throw error
    })
}

export function getFeedbacksFile(filePath: string) {
  return apiClient
    .get(`${apiRoot}Feedbacks/files/${filePath}`, { responseType: 'blob' })
    .then((res) => res)
}

export function deleteFeedbacks(id: string) {
  return apiClient.delete(`${apiRoot}Feedbacks/${id}`)
}

export function getFeedbackNotificationRecipients(): Promise<
  Array<IFeedbackNotificationRecipient>
> {
  return apiClient
    .get(`${apiRoot}NotificationRecipients`)
    .then((res) => res.data)
}

export function postNotificationRecipients(
  recipient: IPostFeedbackNotificationRecipient
) {
  return apiClient.post(`${apiRoot}NotificationRecipients`, recipient)
}

export function putNotificationRecipients(
  id: number,
  recipient: IPostFeedbackNotificationRecipient
) {
  return apiClient.put(`${apiRoot}NotificationRecipients/${id}`, recipient)
}

export function deleteFeedbackNotificationRecipients(id: number) {
  return apiClient.delete(`${apiRoot}NotificationRecipients/${id}`)
}
