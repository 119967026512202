import apiClient from './basicapi'

const apiRoot = 'contact/admin/'

export interface INotificationRecipient {
  id: number
  name: string
  email: string
}

export interface IPostNotificationRecipient {
  name?: string
  email: string
}

export interface IContactRequest {
  id: number
  timestamp: string
  name: string
  email: string
  text: string
}

export interface IAccessibilityContactRequest {
  id: number
  timestamp: string
  url: string
  description: string
  email: string
  hasFile: boolean
}

export function getContactRequests(): Promise<Array<IContactRequest>> {
  return apiClient.get(`${apiRoot}requests`).then((res) => res.data)
}

export function deleteContactRequests(id: number) {
  return apiClient.delete(`${apiRoot}requests/${id}`).then((res) => res.data)
}

export function getAccessibilityContactRequest(): Promise<
  Array<IAccessibilityContactRequest>
> {
  return apiClient
    .get(`${apiRoot}requests/accessibility`)
    .then((res) => res.data)
}

export function getAccessibilityContactRequestImage(id: number) {
  return apiClient.get(`${apiRoot}requests/accessibility/image/${id}`, {
    responseType: 'arraybuffer',
  })
}

export function deleteAccessibilityContactRequests(id: number) {
  return apiClient
    .delete(`${apiRoot}requests/accessibility/${id}`)
    .then((res) => res.data)
}

export function getNotificationRecipients(): Promise<
  Array<INotificationRecipient>
> {
  return apiClient
    .get(`${apiRoot}NotificationRecipients`)
    .then((res) => res.data)
}

export function postNotificationRecipients(
  recipient: IPostNotificationRecipient
) {
  return apiClient.post(`${apiRoot}NotificationRecipients`, recipient)
}

export function putNotificationRecipients(
  id: number,
  recipient: IPostNotificationRecipient
) {
  return apiClient.put(`${apiRoot}NotificationRecipients/${id}`, recipient)
}

export function deleteNotificationRecipients(id: number) {
  return apiClient.delete(`${apiRoot}NotificationRecipients/${id}`)
}
