import React from 'react'
import * as ReactIntl from 'react-intl'
import { WrapPageElementNodeArgs } from 'gatsby'

import Layout from './../src/components/layout/index'

import { messages } from './../src/translations/index'
import { defaultLang } from './../src/translations/index'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import { AuthProvider } from '@providers/AuthProvider'
import { SnackbarProvider } from '@providers/SnackbarProvider'
import { DialogProvider } from '@providers/Dialog'

import createMuiTheme from "../config/theme"

const muitheme = createMuiTheme()

type WrapPageArgs = WrapPageElementNodeArgs & {
  props: {
    pageContext: AT.IPageContext
  }
}

export function wrapPage({ element, props }: WrapPageArgs) {
  return (
    <ReactIntl.IntlProvider
      locale={props.pageContext.locale || defaultLang}
      defaultLocale={defaultLang}
      messages={messages[props.pageContext.locale] || messages[defaultLang]}
    >
      <ThemeProvider theme={muitheme}>
        <SnackbarProvider>
          <AuthProvider path={props.path}>
            <DialogProvider>
              <CssBaseline />
              <Layout {...props}>{element}</Layout>
            </DialogProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ReactIntl.IntlProvider>
  )
}
