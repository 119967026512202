import React, { useState, useCallback, createContext } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

export interface ISnackbarContext {
  open: boolean
  toggleSnackbar: (message: string, alertVariant?: AlertType) => void
}

type AlertType = 'error' | 'success' | 'info' | 'warning' | undefined

const SnackbarContext = createContext<ISnackbarContext>({
  open: false,
  toggleSnackbar: (message: string, alertVariant?: AlertType) => {},
})

export type SnackbarProviderProps = AT.IReactPropsDefaults

export function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState<AlertType>('error')

  const toggleSnackbar = useCallback(
    (message: string, alertVariant?: AlertType) => {
      setMessage(message)
      setAlertVariant(alertVariant || 'info')
      setOpen(true)
    },
    []
  )

  function handleClose() {
    setOpen(false)
  }

  return (
    <SnackbarContext.Provider
      value={{ open: open, toggleSnackbar: toggleSnackbar }}
    >
      {children}
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert elevation={6} variant="filled" severity={alertVariant}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

SnackbarProvider.whyDidYouRender = true

export default SnackbarContext
