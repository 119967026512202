// colors for primary and secondary is only to overwrite default theme colors,
import { PaletteOptions } from '@mui/material/styles/createPalette'
import {
  grey,
  lightGreen,
  green,
  red,
  deepOrange,
  purple,
} from '@mui/material/colors'

// we need to verify which values from our palette might make sense
const Palette: PaletteOptions = {
  mode: 'dark',
  primary: lightGreen,
  secondary: grey,
  info: purple,
  error: deepOrange,
  green: green,
  red: red,
  text: {
    hint: 'rgba(0, 0, 0, 0.38)',
  },
}

export default Palette
